<template>
  <div>
    <H5 v-if="isMobile" />
    <PC v-else />
  </div>
</template>

<script lang="ts" setup>
import { ENUM_PageName } from '~/enums'
import PC from '~/pages/desktop/attractions/list/index.vue'
import H5 from '~/pages/mobile/attractions/list/index.vue'

definePageMeta({
  pageCategory: ENUM_PageName.List,
})
useAutoScrollTop()
const { isMobile } = await useMountedList()
</script>
